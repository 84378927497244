<template>
  <heading1>Vytvořit nový seminář</heading1>
  <seminar-form :is-submitting="isSubmitting" @submit="onSubmit"></seminar-form>
</template>

<script lang="ts" setup>
import SeminarForm from "@/components/seminar/SeminarForm/SeminarForm.vue";
import Heading1 from "@/components/common/heading1.vue";
import type { SeminarFormValues } from "@/components/seminar/SeminarForm/seminar-form";
import { useRouter } from "vue-router";
import { convertFromQuasarDate } from "@/components/seminar/translators/date";
import { useQuasar } from "quasar";
import { useGraphQLMutation } from "@/api/graphql/adapters";
import { CreateSeminarMutationDocument } from "@/api/graphql/documents/CreateSeminar";
import { SeminarType } from "@/api/graphql/codegen/graphql";

const $q = useQuasar();

const { mutate, isLoading: isSubmitting } = useGraphQLMutation(
  CreateSeminarMutationDocument,
  {
    onSuccess: (result) => {
      router.push(`/seminar/${result?.createSeminar.id}`);
    },
    onError: (err: any) => {
      $q.notify({
        color: "negative",
        message: err?.message ?? "Nepodařilo se vytvořit nový seminář",
        progress: true,
      });
    },
  }
);

const router = useRouter();

const onSubmit = async (values: SeminarFormValues) => {
  const date = convertFromQuasarDate(values.date);
  const endDate = values.endDate ? convertFromQuasarDate(values.endDate) : null;
  await mutate({
    seminarInput: {
      ...values,
      date: date.toISOString(),
      endDate: endDate?.toISOString(),
      price: values.type === SeminarType.Idz ? "0" : values.price,
    },
  });
};
</script>
