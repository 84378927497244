import { useQuasar } from "quasar";

export const useNotifications = () => {
  const $q = useQuasar();

  const showRebuildWebNotification = () => {
    $q.notify({
      color: "positive",
      message:
        "Probíhá aktualizace formuláře registrace. Změny se projeví do 2 minut.",
      progress: true,
    });
  };

  return {
    showRebuildWebNotification,
  };
};
