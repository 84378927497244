import { usePdfMaker } from "@/utils/pdf/pdf";
import type {
  Content,
  ContentImage,
  ContentText,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import type { ParticularSeminar } from "@/components/seminar/SeminarTable/types";
import { formatDate, formatDateWithTime } from "date-formatter";
import { SeminarType } from "@/api/graphql/codegen/graphql";
import { translatePaymentMethodToCzech } from "payment-method-translator";

interface GenerateAttendanceListOptions {
  verticalLayout: boolean;
  numOfSignColumns: number;
}

const getLastName = (name: string) => {
  const names = name.trim().split(" ");
  const namesWithoutDegree = names.filter((n) => {
    return ![
      "mgr",
      "ing",
      "phdr",
      "phd",
      "mba",
      "dis",
      "mudr",
      "bc",
      "mga",
    ].includes(n.toLowerCase().replace(".", "").replace(",", ""));
  });

  return namesWithoutDegree[namesWithoutDegree.length - 1];
};

export const useGenerateAttendanceList = () => {
  const { generatePdf } = usePdfMaker();

  const generateAttendanceList = (
    seminar: ParticularSeminar,
    options: GenerateAttendanceListOptions
  ) => {
    const participants = seminar.participants?.sort((a, b) => {
      const aLastName = getLastName(a.name);
      const bLastName = getLastName(b.name);
      return aLastName.localeCompare(bLastName);
    });
    if (!participants || participants.length === 0) return;

    const content: Content = {
      stack: [
        ...(seminar.type === SeminarType.Idz
          ? [
              {
                image: "euMsmtLogo",
                alignment: "center",
                width: 5581 / 14,
                height: 795 / 14,
                margin: [0, 0, 0, 5],
              } as ContentImage,
              {
                text: "Projekt Implementace dlouhodobého záměru Kraje Vysočina.",
                alignment: "center",
                marginBottom: 10,
              } as ContentText,
            ]
          : []),
        {
          alignment: "left",
          fontSize: 9,
          columns: [
            [
              {
                text: "VYSOČINA EDUCATION",
                style: "subheader",
              },
              {
                text: "školské zařízení pro další vzdělávání pedagogických pracovníků",
              },
              {
                text: "příspěvková organizace",
              },
              {
                text: seminar.author?.address ?? "",
                style: "strong",
              },
              {
                text: [
                  seminar.author?.phone ? `tel: ${seminar.author.phone}` : null,
                  seminar.author?.email
                    ? `email: ${seminar.author.email}`
                    : null,
                  "web: http://www.vys-edu.cz",
                ]
                  .filter(Boolean)
                  .join(", "),
              },
              {
                text: `metodik: ${[
                  seminar.author?.degree,
                  seminar.author?.firstName,
                  seminar.author?.lastName,
                ]
                  .join(" ")
                  .trim()}`,
              },
            ],
            ...(seminar.type === SeminarType.Default
              ? [
                  {
                    image: "vysEduLogo",
                    width: 90,
                    height: 90,
                    margin: [5, -10, 5, 0],
                  } as ContentImage,
                ]
              : []),
          ],
        },
        {
          table: {
            widths: ["*"],
            body: [[" "], [" "]],
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? 0 : 1;
            },
            vLineWidth: function () {
              return 0;
            },
          },
        },
        {
          text: `Prezenční listina`,
          style: "subheader",
          marginTop: 10,
          alignment: "center",
        },
        {
          text: `Název semináře: ${seminar.name}`,
          alignment: "left",
          style: "strong",
        },
        {
          text: `Číslo akce: ${seminar.identifier}`,
          alignment: "left",
        },
        {
          text: seminar.lector ? `Lektor: ${seminar.lector}` : "",
          alignment: "left",
        },
        ...(seminar?.price
          ? [
              {
                text: `Kurzovné: ${seminar.price} Kč`,
                alignment: "left",
              } as ContentText,
            ]
          : []),
        {
          text: `Datum: ${formatDateWithTime(seminar.date)} ${
            seminar.endDate ? `- ${formatDateWithTime(seminar.endDate)}` : ""
          }`,
          alignment: "left",
        },
        {
          text: `akreditace: ${seminar.accreditation}`,
          alignment: "left",
          marginBottom: 10,
        },
        {
          text:
            seminar.type === SeminarType.Default
              ? "Souhlasím se zpracováním svých osobních údajů uvedených na této listině a to k prokázání a osvědčení mé přítomnosti na akci. Rovněž souhlasím s uveřejněním fotografií a videozáznamů pořízených na akcích Vysočina Education a jejích partnerů na webových stránkách Vysočina Education, jejích partnerů, nadřízených a kontrolních orgánů, v médiích a v oficiálních materiálech Vysočina Education a jejích partnerů (příručky, brožury, létáky, facebook, apod.)"
              : "Svým podpisem stvrzuji účast na výše uvedené akci a souhlasím se sběrem, zpracováním " +
                "a uchováním osobních údajů za účelem realizace výše uvedeného projektu (tzn. do roku 2041).\n\n" +
                "Současně jsem si vědom/a, že souhlas ke zpracování osobních údajů mohu kdykoliv odvolat.",
          style: "consent",
          marginBottom: 10,
          fontSize: 9,
        },
        {
          table: {
            headerRows: 1,
            widths: [
              15,
              150,
              40,
              170,
              ...Array(options.numOfSignColumns).fill("*"),
            ],
            dontBreakRows: true,
            body: [
              [
                { text: "", style: "tableHeader" },
                { text: "Jméno", style: "tableHeader" },
                {
                  text: "Platba",
                  style: "tableHeader",
                },
                { text: "Škola", style: "tableHeader" },
                ...Array(options.numOfSignColumns).fill({
                  text: options.numOfSignColumns === 1 ? "Podpis" : "",
                  style: "tableHeader",
                }),
              ],
              ...participants.map((participant, index) => [
                {
                  text: `${index + 1}`,
                  style: "cell",
                  alignment: "center",
                },
                {
                  text: participant.name,
                  style: "cell",
                },
                {
                  text: participant.paymentMethod
                    ? translatePaymentMethodToCzech(participant.paymentMethod)
                    : "",
                  style: "cell",
                },
                {
                  text: participant.school,
                  style: "cell",
                },
                ...Array(options.numOfSignColumns).fill(""),
              ]),
            ],
          },
        },
      ],
    };

    const attendanceListDefinition: TDocumentDefinitions = {
      content,
      pageOrientation: options.verticalLayout ? "portrait" : "landscape",
      pageMargins: [25, 25, 25, 40],
      footer: function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + " z " + pageCount,
          marginLeft: 100,
          layout: "noBorders",
        };
      },
      images: {
        vysEduLogo:
          "https://vys-edu.s3.eu-central-1.amazonaws.com/vys-edu-logo.jpeg",
        euMsmtLogo: "https://vys-edu.s3.eu-central-1.amazonaws.com/eu-msmt.png",
      },
      styles: {
        title: {
          bold: true,
        },
        subheader: {
          bold: true,
          fontSize: 15,
        },
        strong: {
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
          fillColor: "#d3d3d3",
        },
        cell: {
          fontSize: 10,
          margin: [0, 7, 0, 7],
        },
        consent: {
          color: "blue",
        },
      },
    };

    return generatePdf(
      attendanceListDefinition,
      `Prezenční listina - ${formatDate(seminar.date)}`
    );
  };

  return {
    generateAttendanceList,
  };
};
