<template>
  <Loading v-if="loading" />
  <template v-else-if="seminar">
    <Heading1>Upravit seminář</Heading1>
    <SeminarForm
      :is-submitting="isSubmitting"
      :seminar="seminar"
      @submit="onSubmit"
    ></SeminarForm>
  </template>
</template>

<script lang="ts" setup>
import type { SeminarFormValues } from "@/components/seminar/SeminarForm/seminar-form";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/common/Loading.vue";
import Heading1 from "@/components/common/heading1.vue";
import SeminarForm from "@/components/seminar/SeminarForm/SeminarForm.vue";
import {
  convertFromQuasarDate,
  convertToQuasarDate,
} from "@/components/seminar/translators/date";
import { useQuasar } from "quasar";
import {
  type GetSeminarsQueryVariables,
  SeminarType,
} from "@/api/graphql/codegen/graphql";
import { useGraphQLMutation, useGraphQLQuery } from "@/api/graphql/adapters";
import { GetSeminarsQueryDocument } from "@/api/graphql/documents/GetSeminars";
import { UpdateSeminarMutationDocument } from "@/api/graphql/documents/UpdateSeminar";
import { useQueryClient } from "@tanstack/vue-query";
import { useNotifications } from "@/utils/notifications/notification";

const $q = useQuasar();

const route = useRoute();
const router = useRouter();
const queryClient = useQueryClient();
const { showRebuildWebNotification } = useNotifications();

const seminarId = computed(() => Number(route.params.id));
const getSeminarsQueryVariables = computed<GetSeminarsQueryVariables>(() => ({
  seminarsInput: { seminarId: seminarId.value },
}));

const {
  data: result,
  isLoading: loading,
  queryKey,
} = useGraphQLQuery(GetSeminarsQueryDocument, getSeminarsQueryVariables);
const seminar = computed<SeminarFormValues | null>(() => {
  if (!result.value) {
    return null;
  }

  const seminar = result.value.seminars[0];

  const converted = {
    identifier: seminar.identifier,
    name: seminar.name,
    date: convertToQuasarDate(new Date(seminar.date)),
    endDate: seminar.endDate
      ? convertToQuasarDate(new Date(seminar.endDate))
      : undefined,
    lector: seminar.lector ?? undefined,
    accreditation: seminar.accreditation ?? "",
    duration: seminar.duration ?? undefined,
    city: seminar.city,
    price: seminar.price ?? undefined,
    type: seminar.type,
  } satisfies SeminarFormValues;

  return converted;
});

const { mutate, isLoading: isSubmitting } = useGraphQLMutation(
  UpdateSeminarMutationDocument,
  {
    onSuccess: async (result) => {
      await Promise.all([
        queryClient.invalidateQueries(queryKey),
        router.push(`/seminar/${result?.updateSeminar.id}`),
      ]);
      showRebuildWebNotification();
    },
    onError: (err: any) => {
      $q.notify({
        color: "negative",
        message: err?.message ?? "Nepodařilo se aktualizovat seminář.",
        progress: true,
      });
    },
  }
);

const onSubmit = async (values: SeminarFormValues) => {
  const date = convertFromQuasarDate(values.date).toISOString();
  const endDate =
    values.endDate === ""
      ? null
      : values.endDate
      ? convertFromQuasarDate(values.endDate).toISOString()
      : undefined;
  await mutate({
    seminarInput: {
      ...values,
      date: date,
      endDate: endDate,
      id: seminarId.value,
      price: values.type === SeminarType.Idz ? "0" : values.price,
    },
  });
};
</script>
