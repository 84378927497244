import { z } from "zod";
import { toFormValidator } from "@vee-validate/zod";
import { SeminarType } from "@/api/graphql/codegen/graphql";

const zodSchema = z.object({
  name: z.string().min(1, "Jméno semináře je povinné"),
  identifier: z.string().min(1, "Zvolte unikátní ID semináře"),
  date: z.string().min(5, "Zvolte termín"),
  endDate: z.string().optional(),
  lector: z.string().optional(),
  accreditation: z.string(),
  city: z.string().min(1, "Uveďte město, ve kterém se bude konat seminář"),
  duration: z.string().optional(),
  price: z.string().optional(),
  type: z.nativeEnum(SeminarType, {
    errorMap: () => ({ message: "Vyberte typ semináře" }),
  }),
});
export const validationSchema = toFormValidator(zodSchema);
export type SeminarFormValues = z.infer<typeof zodSchema>;
